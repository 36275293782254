// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/heart-empty.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/heart.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/heart-filled.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heart[data-v-15b3fadc]{align-items:center;display:flex}.heart__image[data-v-15b3fadc]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;cursor:pointer;height:18px;width:18px}.heart__image[data-v-15b3fadc]:not(.heart__image--empty){background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.heart__image--filled[data-v-15b3fadc],.heart__image[data-v-15b3fadc]:not(.heart__image--disabled):hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")!important}.heart__number[data-v-15b3fadc]{color:#828282;margin-right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
